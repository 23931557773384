<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-header><b-card-title>FAQ list</b-card-title></b-card-header>
      </b-card-header>
      <div class="d-flex justify-content-between flex-wrap">
        <b-button
          v-if="isAdmin"
          class="btn btn-success mb-2 col-sm-12 col-md-2"
          @click="showModal"
          >Add new</b-button
        >
        <search-input @search="setFilter({ search: $event })"></search-input>
      </div>
      <table-spinner :loading="loading"></table-spinner>
      <b-card-body
        v-if="!loading"
        class="d-flex justify-content-between flex-wrap p-0"
      >
        <div
          class="p-1 border expand-container mb-1 mt-1 rounded col-sm-12"
          v-for="faq in faqList"
          :key="faq.id"
        >
          <div
            class="font-weight-bolder d-flex justify-content-between h4 cursor-pointer"
            @click="expand(faq.id)"
          >
            <span>Q: {{ faq.question }}</span>
            <feather-icon
              size="20"
              icon="ChevronDownIcon"
              :class="`${faq.id}`"
            />
            <feather-icon
              size="20"
              icon="ChevronUpIcon"
              :class="`${faq.id}`"
              class="hidden"
            />
          </div>
          <div :class="`${faq.id}`" class="mt-1 hidden">
            A: {{ faq.answer }}
          </div>
          <b-button
            v-if="isAdmin"
            variant="btn-outline"
            class="btn-outline-danger mt-2 ml-auto"
            @click="deleteFaq(faq.id)"
            >Delete</b-button
          >
          <b-button
            v-if="isAdmin"
            variant="btn-outline"
            class="btn-outline-success mt-2 ml-1"
            @click="goToEdit(faq.id)"
            >Edit</b-button
          >
        </div>
      </b-card-body>
      <save-faq-modal
        ref="faq-modal"
        title="Add new FAQ"
        @on-save="onSaveNewFaq"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BButton,
} from "bootstrap-vue";
import { mapActions } from "vuex";
import TableSpinner from "@/layouts/components/TableSpinner.vue";
import { deleteFaq } from "@/services/api/admin";
import debounce from "lodash.debounce";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import SaveFaqModal from "@/layouts/components/faq/SaveFaqModal.vue";
import SearchInput from "@/layouts/components/form/SearchInput.vue";
export default {
  data() {
    return {
      isExpanded: false,
      loading: false,
      newAnswer: "",
      newQuestion: "",
    };
  },
  components: {
    SaveFaqModal,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    FeatherIcon,
    TableSpinner,
    SearchInput,
  },
  mounted() {
    this.getFaqs(this.filter).catch(console.error);
  },
  computed: {
    filter() {
      return {
        ...this.$route.query,
        page: this.$route.query?.page ?? 1,
        per_page: 100,
      };
    },
    meta() {
      return this.$store.getters["faq/getFaqListMeta"];
    },
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    faqList() {
      return this.$store.getters["faq/getFaqListData"];
    },
  },
  methods: {
    ...mapActions({
      getFaqs: "faq/getFaqs",
      createFaq: "faq/createFaq",
    }),
    expand(id) {
      document
        .getElementsByClassName(id)
        .forEach((e) => e.classList.toggle("hidden"));
    },
    setFilter(filter = {}) {
      this.filter.page = 1;
      this.$router.replace({ query: { ...this.filter, ...filter } });
      this.onRefreshPage();
    },
    showModal() {
      this.$refs["faq-modal"].show();
    },
    onSaveNewFaq({ newQuestion, newAnswer }) {
      this.createFaq({ question: newQuestion, answer: newAnswer });
      this.newAnswer = "";
      this.newQuestion = "";
      this.onRefreshPage();
    },
    deleteFaq(id) {
      deleteFaq(id);
      this.onRefreshPage();
    },
    goToEdit(id) {
      this.$router.push({ name: "faq", params: { id: id } });
    },
    onRefreshPage: debounce(async function () {
      this.loading = true;
      try {
        await this.getFaqs(this.filter);
      } finally {
        this.loading = false;
      }
    }, 300),
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}
.dark-layout .h4 {
  color: rgb(182, 182, 182);
}
</style>
