<template>
  <b-modal
    ref="modal"
    cancel-variant="secondary"
    ok-title="Accept"
    cancel-title="Cancel"
    centered
    :title="title"
    @ok="$emit('on-save', { newAnswer, newQuestion })"
  >
    <b-form-group label="Question" label-for="question">
      <b-form-input id="question" v-model="newQuestion" />
    </b-form-group>
    <b-form-group label="Answer" label-for="answer">
      <b-form-input id="answer" v-model="newAnswer" />
    </b-form-group>
  </b-modal>
</template>

<script>
import { BFormGroup, BFormInput } from "bootstrap-vue";
export default {
  name: "SaveFaqModal",
  expose: ["show"],
  props: {
    title: String,
  },
  components: {
    BFormInput,
    BFormGroup,
  },
  data() {
    return {
      newAnswer: "",
      newQuestion: "",
    };
  },
  methods: {
    show() {
      this.$refs.modal.show();
    },
  },
};
</script>

<style></style>
